<template>
  <div class="voorwoord">
    <h1>{{ $t('preface.title') }}</h1>
    <p v-html="$t('preface.text')"></p>
  </div>
</template>

<script>
export default {
  name: 'Voorwoord'
}
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";
  
  .voorwoord {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    max-width: 1400px;
    margin: auto;
    font-family: "DIN-Condensed-Bold", sans-serif;
  }

  h1 {
    padding: 2% 5%;
    color: $red;
    text-transform: uppercase;
    margin: auto;
    width: 100%;
    max-width: 800px;
    text-align: center;
  }

  p {
    padding: 0 5%;
    color: $blue;
    padding-bottom: 40px;
    width: 100%;
    max-width: 800px;
    margin: auto;
  }
</style>